@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

.App {
  text-align: center;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}

.floating-icons {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.floating-icon {
  position: absolute;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.6);
  animation: float 8s ease-in-out infinite;
  animation-delay: var(--delay);
}

.floating-icon:nth-child(1) {
  left: 10%;
  top: 20%;
}
.floating-icon:nth-child(2) {
  right: 15%;
  top: 30%;
}
.floating-icon:nth-child(3) {
  left: 20%;
  bottom: 10%;
}
.floating-icon:nth-child(4) {
  right: 25%;
  bottom: 20%;
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.6;
  }
  50% {
    transform: translateY(-20px) rotate(180deg);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0) rotate(360deg);
    opacity: 0.6;
  }
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  padding: 15px 0;
  z-index: 1000;
  transition: all 0.3s ease;
}

.navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-content {
  max-width: 1224px;
  margin: 0 auto;
  padding: 0 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  font-family: "Press Start 2P", cursive;
  color: #82cbbe;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
}

.nav-link:hover {
  text-shadow: 0 0 10px rgba(130, 203, 190, 0.5);
}

.training-page {
  background-color: #1a1a1a;
  min-height: 100vh;
}

.training-header {
  background-color: #212832;
  padding: 120px 36px 60px;
  text-align: center;
}

.training-header h1 {
  margin-bottom: 20px;
  text-align: center;
}

.training-header p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  margin: 0 auto;
}

.nav-logo {
  width: 80px;
  height: auto;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.3s ease;
}

.nav-logo.visible {
  opacity: 1;
  transform: translateY(0);
}

.nav-icon {
  color: rgba(130, 203, 190);
  width: 30px;
  height: auto;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.3s ease;
}

.nav-icon.visible {
  opacity: 1;
  transform: translateY(0);
}

.hero-content {
  text-align: left;
  max-width: 800px;
}

.hero-logo {
  width: 400px;
  height: auto;
  margin-bottom: 40px;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease;
  display: block;
}

.hero-logo.hidden {
  opacity: 0;
  transform: translateY(20px);
}

.App-header {
  background-color: #1a1a1a;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("https://mcusercontent.com/eb611aa7e3c209fb89b8f546f/_compresseds/516cf037-3d04-4651-be31-796683615ecc.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #212832;
  padding: 165px 36px;
}

.header-content {
  max-width: 1224px;
  width: 100%;
  animation: fadeIn 1.5s ease-in;
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.header-content h1 {
  animation: slideIn 1.2s ease-out;
}

.header-content p {
  animation: slideIn 1.2s ease-out 0.3s backwards;
  text-align: left;
  font-size: 18px;
  line-height: 1.5;
  margin-left: 4px;
  color: #ffffff;
}

h1 {
  font-family: "Press Start 2P", cursive;
  font-size: 32px;
  line-height: 150%;
  text-align: left;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 0 0 10px rgba(130, 203, 190, 0.8),
    0 0 20px rgba(130, 203, 190, 0.5);
  animation: glow 2s ease-in-out infinite;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px rgba(130, 203, 190, 0.8),
      0 0 20px rgba(130, 203, 190, 0.5);
  }
  50% {
    text-shadow: 0 0 20px rgba(130, 203, 190, 0.8),
      0 0 30px rgba(130, 203, 190, 0.5);
  }
  100% {
    text-shadow: 0 0 10px rgba(130, 203, 190, 0.8),
      0 0 20px rgba(130, 203, 190, 0.5);
  }
}

.mission-section {
  background-color: #212832;
  padding: 81px 36px;
  color: white;
}

.mission-content {
  display: flex;
  align-items: center;
  gap: 60px;
  max-width: 1100px;
  margin: 0 auto;
}

.mission-text {
  text-align: left;
  flex: 1;
}

.mission-text h2 {
  font-family: "Press Start 2P", cursive;
  font-size: 24px;
  margin-bottom: 30px;
  color: #82cbbe;
}

.mission-text p {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.9);
}

.mission-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rocket-icon {
  font-size: 200px;
  color: #82cbbe;
  text-shadow: 0 0 20px rgba(130, 203, 190, 0.3);
  animation: float-rocket 4s ease-in-out infinite;
}

@keyframes float-rocket {
  0% {
    transform: translateY(0) rotate(5deg);
  }

  100% {
    transform: translateY(0) rotate(5deg);
  }

  50% {
    transform: translateY(-50px) rotate(8deg);
  }
}

.interactive-services {
  background-color: #1a1a1a;
  padding: 81px 0;
  color: white;
  overflow: hidden;
}

.services-scroll-container {
  position: relative;
  overflow-x: hidden;
  padding: 40px 0;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.services-track {
  display: flex;
  gap: 30px;
  padding: 20px 0;
  animation: scroll 40s linear infinite;
  width: max-content;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.services-track:hover {
  animation-play-state: paused;
}

.training-cta {
  margin-top: 60px;
  padding: 0;
  background: linear-gradient(
    180deg,
    rgba(130, 203, 190, 0.1) 0%,
    rgba(130, 203, 190, 0.05) 100%
  );
  border-radius: 24px;
}

.cta-card {
  backdrop-filter: blur(10px);
  padding: 160px 60px;
  border-radius: 24px;
  text-align: left;
}

.cta-card h3 {
  font-family: "Press Start 2P", cursive;
  font-size: 24px;
  color: #82cbbe;
  margin-bottom: 40px;
  text-align: center;
  text-shadow: 0 0 10px rgba(130, 203, 190, 0.5);
}

.cta-content {
  max-width: 1224px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
  align-items: center;
}

.cta-features {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.cta-feature {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.cta-feature:hover {
  transform: translateX(10px);
}

.cta-feature .feature-icon {
  font-size: 24px;
}

.cta-feature p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
}

.cta-text {
  padding-left: 40px;
  border-left: 2px solid rgba(130, 203, 190, 0.3);
}

.cta-text p {
  font-size: 20px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 30px;
}

.cta-card .pixel-button {
  display: inline-block;
  width: auto;
  padding: 20px 40px;
  font-size: 16px;
}

@media (max-width: 1024px) {
  .cta-content {
    grid-template-columns: 1fr;
  }

  .cta-text {
    padding-left: 0;
    border-left: none;
    border-top: 2px solid rgba(130, 203, 190, 0.3);
    padding-top: 40px;
  }
}

.service-card {
  background: rgba(130, 203, 190, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  padding: 30px;
  min-width: 300px;
  border: 2px solid rgba(130, 203, 190, 0.3);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.service-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #82cbbe, transparent);
  animation: scanline 2s linear infinite;
}

@keyframes scanline {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 20px rgba(130, 203, 190, 0.2);
  border-color: #82cbbe;
}

.service-icon {
  font-size: 32px;
  margin-bottom: 20px;
  background: rgba(130, 203, 190, 0.1);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid rgba(130, 203, 190, 0.3);
}

.service-card h3 {
  font-family: "Press Start 2P", cursive;
  font-size: 16px;
  margin-bottom: 15px;
  color: #82cbbe;
  text-shadow: 0 0 10px rgba(130, 203, 190, 0.5);
}

.service-card p {
  font-size: 16px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.download-section {
  background-color: #1a1a1a;
  padding: 81px 36px;
  color: white;
}

.pixel-text {
  font-family: "Press Start 2P", cursive;
  color: #82cbbe;
  text-shadow: 0 0 10px rgba(130, 203, 190, 0.5);
}

.download-content {
  margin-top: 40px;
  padding: 20px;
}

.download-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  border: 2px solid rgba(130, 203, 190, 0.3);
  max-width: 900px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .download-card {
    flex-direction: row;
    padding: 40px;
    gap: 40px;
  }
}

.pixel-art-icon {
  font-size: 36px;
  animation: bounce 2s infinite;
}

@media (min-width: 768px) {
  .pixel-art-icon {
    font-size: 48px;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.download-text {
  flex: 1;
  text-align: center;
}

.download-text p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.9);
}

@media (min-width: 768px) {
  .download-text {
    text-align: left;
  }
  
  .download-text p {
    font-size: 18px;
  }
}

.download-button {
  display: inline-block;
  padding: 12px 20px;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  color: #82cbbe;
  background-color: transparent;
  border: 2px solid #82cbbe;
  border-radius: 4px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.download-button:hover {
  background-color: rgba(130, 203, 190, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(130, 203, 190, 0.3);
}

.download-button:active {
  transform: translateY(0);
}

.game-section {
  background-color: #1a1a1a;
  padding: 81px 36px;
  color: white;
}

@media screen and (max-width: 768px) {
  .game-section {
    display: none;
  }
}

.game-container {
  max-width: 800px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 40px;
  border: 2px solid rgba(130, 203, 190, 0.3);
}

.game-area {
  width: 600px;
  height: 400px;
  margin: 30px auto;
  background: linear-gradient(rgba(130, 203, 190, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(130, 203, 190, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
  border: 2px solid #82cbbe;
  position: relative;
  cursor: none;
  overflow: hidden;
}

.bow {
  position: absolute;
  width: 24px;
  height: 4px;
  background: #82cbbe;
  transform-origin: center;
}

.bow::before {
  content: "";
  position: absolute;
  right: -8px;
  top: -8px;
  border-left: 10px solid #82cbbe;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.bow::after {
  content: "";
  position: absolute;
  left: -4px;
  top: -6px;
  border-right: 6px solid #82cbbe;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.arrow {
  position: absolute;
  width: 20px;
  height: 4px;
  background: #82cbbe;
  transform-origin: center;
}

.arrow::before {
  content: "";
  position: absolute;
  right: -8px;
  top: -8px;
  border-left: 10px solid #82cbbe;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.target {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fc00ff;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(252, 0, 255, 0.5);
}

.game-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.control-hint {
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  color: rgba(130, 203, 190, 0.7);
}

.game-status {
  font-family: "Press Start 2P", cursive;
  color: #82cbbe;
  margin: 20px 0;
  min-height: 20px;
}

.game-score {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-family: "Press Start 2P", cursive;
  font-size: 14px;
  color: #82cbbe;
}

.button-text {
  position: relative;
  z-index: 1;
}

.button-border {
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid #82cbbe;
  border-radius: 4px;
  animation: borderGlow 2s infinite;
}

@keyframes borderGlow {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.content-container {
  max-width: 1224px;
  margin: 0 auto;
  padding: 0 20px;
}

.contact-section {
  background-color: #1a1a1a;
  padding: 81px 36px;
  color: white;
}

.contact-title {
  margin-bottom: 30px;
}

.contact-subtitle {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.9);
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 40px;
  border: 2px solid rgba(130, 203, 190, 0.3);
}

.form-group {
  margin-bottom: 25px;
}

.pixel-label {
  display: block;
  margin-bottom: 10px;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  color: #82cbbe;
}

.pixel-input {
  width: 100%;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid #82cbbe;
  border-radius: 4px;
  color: white;
  font-family: "Press Start 2P", cursive;
  font-size: 14px;
  transition: all 0.3s ease;
}

.pixel-input:focus {
  outline: none;
  box-shadow: 0 0 15px rgba(130, 203, 190, 0.3);
  background-color: rgba(255, 255, 255, 0.15);
}

.pixel-button {
  width: 100%;
  padding: 15px 30px;
  font-family: "Press Start 2P", cursive;
  font-size: 14px;
  color: #82cbbe;
  background-color: transparent;
  border: 2px solid #82cbbe;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.pixel-button:hover {
  background-color: rgba(130, 203, 190, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(130, 203, 190, 0.3);
}

.pixel-button:active {
  transform: translateY(0);
}

.ai-section {
  background-color: #1a1a1a;
  padding: 81px 36px;
  color: white;
}

.ai-section h2 {
  font-family: "Press Start 2P", cursive;
  color: #82cbbe;
  margin-bottom: 40px;
}

.glitch {
  position: relative;
  text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
    0.025em 0.04em 0 #fffc00;
  animation: glitch 725ms infinite;
}

.glitch span {
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
  }
  15% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
  }
  16% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  49% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  50% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  }
  99% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  }
  100% {
    text-shadow: -0.05em 0 0 #00fffc, -0.025em -0.04em 0 #fc00ff,
      -0.04em -0.025em 0 #fffc00;
  }
}

.ai-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
}

.ai-card {
  perspective: 1000px;
  height: 300px;
}

.ai-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.ai-card:hover .ai-card-inner {
  transform: rotateY(180deg);
}

.ai-card-front,
.ai-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.ai-card-front h3 {
  font-family: "Press Start 2P", cursive;
  font-size: 18px;
  color: #82cbbe;
  margin-bottom: 20px;
}

.ai-card-back {
  transform: rotateY(180deg);
  background: rgba(130, 203, 190, 0.1);
}

.ai-card-back p {
  font-size: 16px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.ai-features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 40px;
}

.feature {
  text-align: center;
  flex: 1;
  min-width: 200px;
}

.feature-icon {
  font-size: 40px;
  margin-bottom: 15px;
}

.feature h4 {
  font-family: "Press Start 2P", cursive;
  font-size: 16px;
  color: #82cbbe;
  margin-bottom: 10px;
}

.feature p {
  color: rgba(255, 255, 255, 0.8);
}

.training-section {
  background-color: #1a1a1a;
  padding: 81px 36px;
  color: white;
}

.training-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-top: 40px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.training-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  border: 2px solid rgba(130, 203, 190, 0.3);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.training-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 20px rgba(130, 203, 190, 0.2);
}

.training-card-header {
  background: rgba(130, 203, 190, 0.1);
  padding: 30px;
  text-align: center;
}

.training-card-header h3 {
  font-family: "Press Start 2P", cursive;
  font-size: 18px;
  color: #82cbbe;
  margin-bottom: 15px;
}

.price {
  font-family: "Press Start 2P", cursive;
  font-size: 24px;
  color: #82cbbe;
}

.training-card-content {
  padding: 30px;
}

.training-card-content ul {
  list-style: none;
  padding: 0;
  margin: 0 0 30px 0;
}

.training-card-content li {
  padding: 10px 0;
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  padding-left: 25px;
}

.training-card-content li:before {
  content: "→";
  position: absolute;
  left: 0;
  color: #82cbbe;
}

.training-card .pixel-button {
  width: 100%;
  margin-top: 20px;
}

.ai-training-page {
  background-color: #1a1a1a;
  min-height: 100vh;
  padding-bottom: 60px;
}

.ai-training-content {
  padding: 60px 0;
}

.training-modules {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 80px;
}

.module-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 30px;
  border: 2px solid rgba(130, 203, 190, 0.3);
}

.module-card h2 {
  font-family: "Press Start 2P", cursive;
  font-size: 18px;
  color: #82cbbe;
  margin-bottom: 20px;
}

.module-card ul {
  list-style: none;
  padding: 0;
}

.module-card li {
  color: rgba(255, 255, 255, 0.9);
  padding: 10px 0;
  padding-left: 25px;
  position: relative;
}

.module-card li:before {
  content: "→";
  position: absolute;
  left: 0;
  color: #82cbbe;
}

.training-benefits {
  margin-top: 80px;
  text-align: center;
}

.training-benefits h2 {
  margin-bottom: 40px;
}

.benefits-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .benefits-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    padding: 0;
  }
}

.benefit-item {
  padding: 20px;
}

.benefit-icon {
  font-size: 40px;
  margin-bottom: 20px;
  display: block;
}

.benefit-item h3 {
  font-family: "Press Start 2P", cursive;
  font-size: 14px;
  color: #82cbbe;
  margin-bottom: 15px;
}

.benefit-item p {
  color: rgba(255, 255, 255, 0.9);
}

.training-pricing {
  margin-top: 80px;
  text-align: center;
}

.training-pricing h2 {
  margin-bottom: 40px;
}

.pricing-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .pricing-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    padding: 0;
  }
}

.pricing-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 30px;
  border: 2px solid rgba(130, 203, 190, 0.3);
  display: flex;
  flex-direction: column;
}

.pricing-card.featured {
  background: rgba(130, 203, 190, 0.15);
  border-color: #82cbbe;
  position: relative;
}

.pricing-card.featured::before {
  content: "BEST VALUE";
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: #82cbbe;
  color: #1a1a1a;
  padding: 5px 15px;
  border-radius: 12px;
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
}

.pricing-header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(130, 203, 190, 0.3);
  margin-bottom: 20px;
}

.pricing-header h3 {
  font-family: "Press Start 2P", cursive;
  font-size: 18px;
  color: #82cbbe;
  margin-bottom: 15px;
}

.pricing-header .price {
  font-family: "Press Start 2P", cursive;
  font-size: 36px;
  color: #82cbbe;
  margin-bottom: 10px;
}

.pricing-header p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
}

.pricing-features {
  flex-grow: 1;
  margin-bottom: 30px;
}

.pricing-features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pricing-features li {
  color: rgba(255, 255, 255, 0.9);
  padding: 12px 0;
  position: relative;
  padding-left: 25px;
}

.pricing-features li:before {
  content: "→";
  position: absolute;
  left: 0;
  color: #82cbbe;
}

.schedule-explainer {
  margin: 40px 0;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.schedule-explainer p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  line-height: 1.6;
  margin: 20px 0;
}

.format-highlights {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  padding: 0 10px;
}

@media (max-width: 768px) {
  .format-highlights {
    flex-direction: column;
    align-items: center;
  }
}

.highlight-item {
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(130, 203, 190, 0.05);
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid rgba(130, 203, 190, 0.1);
}

.highlight-icon {
  font-size: 20px;
}

.highlight-item p {
  margin: 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
}

.training-schedule {
  margin: 80px 0;
  text-align: center;
}

.training-schedule h2 {
  margin-bottom: 40px;
}

.schedule-options {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .schedule-options {
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    padding: 0;
  }
}

.schedule-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 30px;
  border: 2px solid rgba(130, 203, 190, 0.3);
  text-align: left;
}

.schedule-card h3 {
  font-family: "Press Start 2P", cursive;
  font-size: 18px;
  color: #82cbbe;
  margin-bottom: 10px;
  text-align: center;
}

.session-subtitle {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: rgba(130, 203, 190, 0.8);
  text-align: center;
  margin-bottom: 30px;
  font-style: italic;
}

.schedule-timeline {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background: rgba(130, 203, 190, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(130, 203, 190, 0.1);
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .timeline-item {
    flex-direction: row;
    gap: 20px;
    padding: 20px;
  }
}

.timeline-item:hover {
  background: rgba(130, 203, 190, 0.1);
  transform: translateX(5px);
}

.timeline-item.break {
  background: rgba(130, 203, 190, 0.02);
  border-style: dashed;
}

.timeline-item.break:hover {
  background: rgba(130, 203, 190, 0.05);
}

.timeline-item .time {
  font-family: "Press Start 2P", cursive;
  font-size: 12px;
  color: #82cbbe;
  min-width: 70px;
  padding: 8px;
  background: rgba(130, 203, 190, 0.1);
  border-radius: 4px;
  text-align: center;
  height: fit-content;
}

.item-content h4 {
  font-family: "Press Start 2P", cursive;
  font-size: 14px;
  color: #82cbbe;
  margin-bottom: 12px;
  text-shadow: 0 0 10px rgba(130, 203, 190, 0.3);
}

.item-content p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  line-height: 1.4;
}

.App-footer {
  background-color: #212932;
  color: #b3b3b3;
  padding: 45px 36px;
  font-size: 14px;
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 30px;
    line-height: 125%;
  }

  .templateSection {
    padding-right: 0;
    padding-left: 0;
  }
}
